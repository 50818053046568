<template>
  <b-row v-if="user">
    <b-col cols="12">
      <validation-observer ref="formRules">
        <b-form-group label-for="new-password" :label="`重置用户 [${user.email}] 的密码`">
          <validation-provider
            #default="{ errors }"
            name="*密码*"
            rules="required|password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="new-password"
                v-model="newPwd"
                :type="passwordFieldType"
                name="password"
                placeholder="输入新密码"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePassword"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-col>

    <b-col cols="12" class="text-right">
      <b-button variant="primary" class="mt-2 mr-1" @click="save">
        保存修改
      </b-button>
      <b-button
        variant="outline-secondary"
        class="mt-2"
        @click.prevent="cancel"
      >
        取消
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, password } from "@core/utils/validations/validations";

export default {
  name: "UserRestPwd",

  props: ["user"],

  emits: ["save"],

  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      editUser: null,

      passwordFieldType: "password",
      passwordToggleIcon: "EyeIcon",

      newPwd: null,
    };
  },

  mounted() {},

  methods: {
    save() {
      this.$refs.formRules.validate().then((success) => {
        if (success) {
          this.$emit("save", this.newPwd);
        }
      });
    },

    cancel() {
      this.$emit("cancel");
    },

    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";

      this.passwordToggleIcon =
        this.passwordToggleIcon === "EyeIcon" ? "EyeIcon" : "EyeOffIcon";
    },
  },
};
</script>
