<template>
  <b-card>
    <b-row>
      <b-col cols="8">
        <b-input-group>
          <b-form-input
            type="text"
            v-model="query_keyword"
            placeholder="公司名称、联系人、电话、邮箱"
            @keypress.enter="doSearch"
          />
          <b-input-group-append is-text>
            <feather-icon
              icon="SearchIcon"
              class="cursor-pointer"
              @click="doSearch"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col cols="2">
        <b-form-checkbox
          v-model="query_it_only"
          class="mt-1"
          @change="doSearch"
        >
          只显示平台用户
        </b-form-checkbox>
      </b-col>
      <b-col cols="2" class="mb-1 text-right">
        <b-button @click="onNew" variant="outline-primary" size="sm">
          <feather-icon icon="UserPlusIcon" class="mr-50" />
          <span class="align-middle">新建</span></b-button
        >
      </b-col>
  </b-row>
  <br/>
  <b-row>
       <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="users"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>
          <template #cell(roleId)="data">
            <span>{{ roleName(data.item.roleId) }}</span>
          </template>
          <template #cell(eid)="data">
            <span>{{ eidName(data.item.eid) }}</span>
          </template>

          <template #cell(action)="data">
           <div class="text-left text-nowrap">
            <b-button variant="flat-primary" @click="onResetPwd(data.item)">
              <feather-icon icon="CheckCircleIcon" class="mr-50" />
              <span class="align-middle">重置密码</span></b-button
            >
            <b-button
              variant="flat-primary"
              @click="onEdit(data.item)"
              :disabled="data.item.eid != 1"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
              <span class="align-middle">编辑</span></b-button
            >
            <b-button
              variant="flat-danger"
              @click="onDelete(data.item)"
              :disabled="data.item.default == 1 || data.item.eid != 1"
            >
              <feather-icon icon="DeleteIcon" class="mr-50" />
              <span class="align-middle">删除</span></b-button
            >
           </div>  
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <b-modal
      centered
      title="修改用户密码"
      v-model="showModalUserResetPwd"
      hide-footer
    >
      <user-reset-pwd
        :user="currentUser"
        @save="onUserResetPwd"
        @cancel="showModalUserResetPwd = false"
      />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormCheckbox,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BModal,
} from "bootstrap-vue";
import UserEdit from "./UserEdit.vue";
import UserResetPwd from "./UserResetPwd.vue";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    UserEdit,
    UserResetPwd,
    BModal,
  },

  data() {
    return {
      users: null,
      roles: null,
      enterprises: null,
      query_it_only: true,
      query_compliance: null,
      query_keyword: null,

      currentPage: null,
      totalRows: null,
      perPage: 30,
      sortBy: null,
      sortDesc: null,
      sortDirection: null,
      fields: [
        {
          key: "avatar",
          label: "头像",
        },
        {
          key: "nickName",
          label: "昵称",
        },
        {
          key: "email",
          label: "邮箱",
        },
        {
          key: "eid",
          label: "企业",
        },
        {
          key: "roleId",
          label: "角色",
        },
        {
          key: "action",
          label: "操作",
          tdClass: "text-center",
          thClass: "text-left",
        },
      ],

      currentUser: null,
      currentDlg: null, // edit | resetPwd
      complianceOptions: [
        { value: "", text: "企业名称" },
        "GDPR",
        "CCPA",
        "PIPL",
      ],

      showModalUserResetPwd: false,
    };
  },

  created() {
    // load all org
    this.doSearch();
  },

  methods: {
    doSearch() {
      // let id = 1;
      let params = {};
      if (this.query_it_only) params.eid = 1;
      if (this.query_keyword) params.q = this.query_keyword;

      this.$http.get("/users", { params }).then((res) => {
        this.users = res.data.data.list;
        // console.log(this.users);
        this.totalRows = res.data.data.total;
      });

      this.$http.get("/roles?&p=1&ps=100").then((res) => {
        this.roles = res.data.data.list;
        // console.log(this.roles);
      });
      this.$http.get("/enterprises?&p=1&ps=100").then((res) => {
        this.enterprises = res.data.data.list;
        // console.log(this.enterprises)
        // console.log(this.roles);
      });
      // console.log(this.query_industry);
    },
    // get role name by roleId from roles
    roleName(id) {
      // find the role which id=id
      let role = this.roles ? this.roles.find((item) => item.id == id) : null;

      // return the role.name if the role is found
      return role ? role.name : "";
    },

    eidName(id) {
      let enterprise = this.enterprises
        ? this.enterprises.find((item) => item.id == id)
        : null;
      return enterprise ? enterprise.name : "";
    },

    onNew() {
      this.$router.push({
        name: "user-edit",
        params: {
          id: "new",
        },
      });
    },

    onEdit(user) {
      // this.$refs.modalUserEdit.show();
      this.$router.push({
        name: "user-edit",
        params: {
          id: user.id,
        },
      });
    },

    onDelete(user) {
      this.$swal({
        title: "删除确认",
        text: "是否确认删除该用户!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "确认",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http.delete("/users/" + user.id).then((res) => {
            this.$swal({
              title: "删除成功",
              text: "该用户已删除!",
              icon: "success",
              button: "确认",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.doSearch();
          });
        }
      });
    },

    onResetPwd(user) {
      this.currentUser = user;
      this.showModalUserResetPwd = true;
    },

    onUserResetPwd(pwd) {
      // console.log("reset new pwd", pwd);
      this.$http
        .put("/users/" + this.currentUser.id, { password: pwd })
        .then((res) => {
          this.$swal({
            title: "重置密码成功",
            text: "密码已重置为：" + pwd,
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "确认",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.showModalUserResetPwd = false;
              // this.currentUser = null;
            }
          });
        });
    },
    onUserResetPwdCancel() {
      this.showModalUserResetPwd = false;
    },
  },
};
</script>